import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "components/layout";
import InvestigationsContent from "components/investigations-page/investigations-content";
import SEO from "components/seo";
import PageHeader from "components/PageHeader";
import { LocaleContext } from "context/locale-context";
import useTranslations from "hooks/useTranslations";

function InvestigationsDatasets({ data }) {
  const reports = data.reports.edges;
  const datasets = data.datasets.edges;
  const locale = useContext(LocaleContext);
  const tr = useTranslations();
  const status = `${reports.length} ${tr("Investigations")} | ${
    datasets.length
  } ${tr("Datasets")}`;
  const metadata = { title: "investigation" };
  return (
    <Layout className={locale}>
      <SEO metadata={metadata} />
      <PageHeader
        title="Investigations"
        desc="config.investigations.description"
        status={status}
      />
      <InvestigationsContent datasets={datasets} reports={reports} />
    </Layout>
  );
}

InvestigationsDatasets.propTypes = {
  data: PropTypes.object,
};

export default InvestigationsDatasets;
export const pageQuery = graphql`
  query InvestigationsData {
    site {
      siteMetadata {
        title
      }
    }
    reports: allStrapiInvestigation {
      edges {
        node {
          title
          date
          desc
          type
          dataset
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 260, placeholder: BLURRED)
              }
            }
          }
          slug
          locale
          localizations {
            data {
              attributes {
                title
                date
                desc
                type
                dataset
                locale
              }
            }
          }
        }
      }
    }
    datasets: allStrapiDataset {
      edges {
        node {
          slug
          title
          date
          desc
          url
          dataset
          external
          type
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 260, placeholder: BLURRED)
              }
            }
          }
          localizations {
            data {
              attributes {
                title
                date
                desc
                url
                dataset
                external
                type
              }
            }
          }
        }
      }
    }
  }
`;
