import React from "react";
import { groupBy } from "ramda";
import useTranslations from "hooks/useTranslations";
import CardGroup from "components/investigations-page/CardGroup";

const InvestigationsContent = ({ datasets, reports }) => {
  const tr = useTranslations();
  const groupedReports = groupBy((n) => n.node.dataset)([
    ...datasets,
    ...reports,
  ]);
  return Object.entries(groupedReports).map(([key, items]) => (
    <CardGroup key={key} title={tr(key)} items={items} />
  ));
};

export default InvestigationsContent;
